export const authMenu = {
	login: {
		id: 'login',
		text: 'Login',
		path: '/login',
		hide: true,
	},
	joinTeam: {
		id: 'accept_invitation',
		text: 'Join Our Team',
		path: '/accept_invitation',
		hide: true,
	},
	forgetGroup: {
		id: 'forget',
		text: 'Reset Password',
		hide: true,
		subMenu: {
			forgetPassword: {
				id: 'forgetPassword',
				text: 'Get Help',
				path: '/forgot-password',
				hide: true,
			},
			confirmation: {
				id: 'confirmation',
				text: 'Verify your account',
				path: '/verify-account',
				hide: true,
			},
			resetPassword: {
				id: 'reset',
				text: 'Reset your password now',
				path: '/reset-password',
				hide: true,
			},
		},
	},
};

export const dashboardMenu = {
	dashboard: {
		id: 'dashboard',
		text: 'Dashboard',
		path: '/',
		icon: 'Dashboard',
		permission: null,
	},
	users: {
		id: 'users',
		text: 'Users',
		path: '/users',
		icon: 'Person',
		permission: 'view users',
	},
	agents: {
		id: 'agents',
		text: 'Agents',
		path: '/agents',
		icon: 'PersonPinCircle',
		permission: 'view agents',
	},
	notifications: {
		id: 'notifications',
		text: 'Notifications',
		path: '/notifications',
		icon: 'PhoneIphone',
		permission: 'view notifications',
	},
	sms: {
		id: 'sms',
		text: 'SMS',
		path: '/sms',
		icon: 'Message',
		permission: 'view sms',
	},
	reports: {
		id: 'reports',
		text: 'Reports',
		path: '/reports',
		icon: 'FileArrowDown',
		permission: 'view reports',
	},
	imports: {
		id: 'imports',
		text: 'Import',
		path: '/imports',
		icon: 'CloudUpload',
		permission: 'view imports',
	},
	importHistory: {
		id: 'imports-history',
		text: 'Import History',
		path: '/import-history',
		icon: 'ClockHistory',
		permission: 'view import history',
	},
	addStaffID: {
		id: 'add-staff-id',
		text: 'Add new staff ID',
		path: '/add-staff-id',
		icon: 'Person',
		permission: 'view management',
	},
};

export const profileMenu = {
	profile: {
		id: 'profile',
		text: 'Profile',
		path: '/profile',
		icon: 'Person',
		hide: true,
	},
};

export const loansMenu = {
	loans: {
		id: 'loans',
		text: 'Loans',
		icon: 'Activity',
		permission: 'view loans',
	},
	publicLoans: {
		id: 'public-loans',
		text: 'Public Sector Loan',
		icon: 'FileBreakFill',
		path: '/public-sector',
		permission: 'view public loans',
	},
};

export const financeMenu = {
	finance: {
		id: 'investment',
		text: 'Investment',
		icon: 'Activity',
		permission: 'view investmentsp',
	},
	packages: {
		id: 'packages',
		text: 'Packages',
		icon: 'DynamicFeed',
		path: '/packages',
		permission: 'view packages',
	},
	pools: {
		id: 'pools',
		text: 'Pools',
		icon: 'Dns',
		path: '/pools',
		permission: 'view pools',
	},
	investments: {
		id: 'investments',
		text: 'Investments',
		icon: 'BarChartLineFill',
		path: '/investments',
		permission: 'view investments',
		subMenu: {
			investmentHistory: {
				id: 'customers-investemnt-history',
				text: 'Investment History',
				icon: '',
				path: '/investments',
			},
			bookInvestment: {
				id: 'consumer-investment-booking',
				text: 'Book Investment',
				icon: '',
				path: '/investments-bookings',
			},
		},
	},
	savings: {
		id: 'savings',
		text: 'Target Savings',
		icon: 'PieChartFill',
		path: '/target-savings',
		permission: 'view savings',
	},
};

export const management = {
	management: {
		id: 'management',
		text: 'Management',
		icon: 'Earbuds',
		permission: 'view management',
	},
	roles: {
		id: 'roles',
		text: 'Roles',
		icon: 'Earbuds',
		path: '/roles',
		permission: 'view roles',
	},
	administrators: {
		id: 'admins',
		text: 'Administrators',
		icon: 'FollowTheSigns',
		path: '/administrators',
		permission: 'view administrators',
	},
};
