import React, { lazy } from 'react';
import { authMenu, dashboardMenu, financeMenu, loansMenu, management, profileMenu } from '../menu';

const AUTH = {
	LOGIN: lazy(() => import('../pages/auth/Login')),
	FORGET_PASSWORD: lazy(() => import('../pages/auth/forget/Forget')),
	CONFIRM_RESET: lazy(() => import('../pages/auth/forget/ConfirmForget')),
	RESET: lazy(() => import('../pages/auth/forget/ResetPassword')),
	JOIN_TEAM: lazy(() => import('../pages/auth/JoinTeam')),
};

const LANDING = {
	DASHBOARD: lazy(() => import('../pages/dashboard/DashboardPage')),
	AGENTS: {
		INDEX: lazy(() => import('../pages/user/agents/AgentIndex')),
		SHOW_AGENT: lazy(() => import('../pages/user/agents/AgentSingle')),
	},
	CUSTOMERS: {
		INDEX: lazy(() => import('../pages/user/customers/CustomerIndex')),
		SHOW_CUSTOMER: lazy(() => import('../pages/user/customers/CustomerSingle')),
	},
	NOTIFICATIONS: lazy(() => import('../pages/notifications/NotificationIndex')),
	SMS: lazy(() => import('../pages/notifications/SendSms')),
	REPORT: lazy(() => import('../pages/reports/Report')),
	IMPORT: lazy(() => import('../pages/imports/Import')),
	IMPORTHISTORY: lazy(() => import('../pages/imports/Index')),
	SINGLE_IMPORT: lazy(() => import('../pages/imports/SingleImport')),
	UPLOAD_STAFF_ID: lazy(() => import('../pages/imports/StaffID')),
};

const PROFILE = {
	PROFILE: lazy(() => import('../pages/profile/Profile')),
};

const LOAN_MENU = {
	PUBLIC_LOAN: lazy(() => import('../pages/Loans/PublicIndex')),
	PUBLIC_SINGLE: lazy(() => import('../pages/Loans/PublicSingle')),
};

const INVESTMENTS = {
	PACKAGES: lazy(() => import('../pages/packages/PackageIndex')),
	SINGLE_PACKAGE: lazy(() => import('../pages/packages/PackageShow')),
	POOLS: lazy(() => import('../pages/pools/PoolIndex')),
	SINGLE_POOL: lazy(() => import('../pages/pools/PoolShow')),
	SINGLE_POOL_INVESTMENTS: lazy(() => import('../pages/pools/ListInvestmentsByPool')),
	INVESTMENTS: lazy(() => import('../pages/investments/InvestmentLists')),
	BOOK_INVESTMENT: lazy(() => import('../pages/investments/AddInvestment')),
	SINGLE_INVESTMENT: lazy(() => import('../pages/investments/SingleInvestment')),
	SINGLE_INVESTMENT_USER: lazy(() => import('../pages/investments/SIngleUserInvestment')),
	//
	SAVINGS: lazy(() => import('../pages/savings/SavingIndex')),
	SINGLE_SAVING: lazy(() => import('../pages/savings/SingleSavings')),
	SINGLE_SAVING_USER: lazy(() => import('../pages/savings/SingleUserSaving')),
};

const ADMINISTRATIONS = {
	ROLES: lazy(() => import('../pages/administration/Role')),
	EDIT_ROLE: lazy(() => import('../pages/administration/EditRole')),
	STAFF: lazy(() => import('../pages/administration/Administrators')),
	PROFILE: lazy(() => import('../pages/profile/Profile')),
};

const presentation = [
	/**
	 * Auth
	 */
	{
		path: authMenu.login.path,
		element: <AUTH.LOGIN />,
		exact: true,
	},
	{
		path: authMenu.forgetGroup.subMenu.forgetPassword.path,
		element: <AUTH.FORGET_PASSWORD />,
		exact: true,
	},
	{
		path: `${authMenu.joinTeam.path}/:slug`,
		element: <AUTH.JOIN_TEAM />,
		exact: true,
	},
	{
		path: authMenu.forgetGroup.subMenu.confirmation.path,
		element: <AUTH.CONFIRM_RESET />,
		exact: true,
	},
	{
		path: authMenu.forgetGroup.subMenu.resetPassword.path,
		element: <AUTH.RESET />,
		exact: true,
	},
	/**
	 * Landing
	 */
	{
		path: dashboardMenu.dashboard.path,
		element: <LANDING.DASHBOARD />,
		exact: true,
	},
	{
		path: dashboardMenu.agents.path,
		element: <LANDING.AGENTS.INDEX />,
		exact: true,
	},
	{
		path: `${dashboardMenu.agents.path}/:id`,
		element: <LANDING.AGENTS.SHOW_AGENT />,
		exact: true,
	},
	{
		path: dashboardMenu.users.path,
		element: <LANDING.CUSTOMERS.INDEX />,
		exact: true,
	},
	{
		path: `${dashboardMenu.users.path}/:id`,
		element: <LANDING.CUSTOMERS.SHOW_CUSTOMER />,
		exact: true,
	},
	{
		path: dashboardMenu.notifications.path,
		element: <LANDING.NOTIFICATIONS />,
		exact: true,
	},
	{
		path: dashboardMenu.sms.path,
		element: <LANDING.SMS />,
		exact: true,
	},
	{
		path: dashboardMenu.imports.path,
		element: <LANDING.IMPORT />,
		exact: true,
	},
	{
		path: dashboardMenu.importHistory.path,
		element: <LANDING.IMPORTHISTORY />,
		exact: true,
	},
	{
		path: `${dashboardMenu.importHistory.path}/:id`,
		element: <LANDING.SINGLE_IMPORT />,
		exact: true,
	},
	{
		path: dashboardMenu.addStaffID.path,
		element: <LANDING.UPLOAD_STAFF_ID />,
		exact: true,
	},
	{
		path: dashboardMenu.reports.path,
		element: <LANDING.REPORT />,
		exact: true,
	},
	/**
	 * Profile
	 */
	{
		path: profileMenu.profile.path,
		element: <PROFILE.PROFILE />,
		exact: true,
	},
	/**
	 * Loan Menu
	 */
	{
		path: loansMenu.publicLoans.path,
		element: <LOAN_MENU.PUBLIC_LOAN />,
		exact: true,
	},
	{
		path: `${loansMenu.publicLoans.path}/:id`,
		element: <LOAN_MENU.PUBLIC_SINGLE />,
		exact: true,
	},
	/**
	 * FInance Menu
	 */
	{
		path: financeMenu.packages.path,
		element: <INVESTMENTS.PACKAGES />,
		exact: true,
	},
	{
		path: `${financeMenu.packages.path}/:id`,
		element: <INVESTMENTS.SINGLE_PACKAGE />,
		exact: true,
	},
	{
		path: financeMenu.pools.path,
		element: <INVESTMENTS.POOLS />,
		exact: true,
	},
	{
		path: `${financeMenu.pools.path}/:id`,
		element: <INVESTMENTS.SINGLE_POOL />,
		exact: true,
	},
	{
		path: `${financeMenu.pools.path}/investments/:id`,
		element: <INVESTMENTS.SINGLE_POOL_INVESTMENTS />,
		exact: true,
	},
	{
		path: financeMenu.investments.subMenu.investmentHistory.path,
		element: <INVESTMENTS.INVESTMENTS />,
		exact: true,
	},
	{
		path: financeMenu.investments.subMenu.bookInvestment.path,
		element: <INVESTMENTS.BOOK_INVESTMENT />,
		exact: true,
	},
	{
		path: `${financeMenu.investments.path}/:id`,
		element: <INVESTMENTS.SINGLE_INVESTMENT_USER />,
		exact: true,
	},
	{
		path: `${financeMenu.investments.path}/view-details/:id`,
		element: <INVESTMENTS.SINGLE_INVESTMENT />,
		exact: true,
	},
	{
		path: financeMenu.savings.path,
		element: <INVESTMENTS.SAVINGS />,
		exact: true,
	},
	{
		path: `${financeMenu.savings.path}/view-details/:id`,
		element: <INVESTMENTS.SINGLE_SAVING />,
		exact: true,
	},
	{
		path: `${financeMenu.savings.path}/:id`,
		element: <INVESTMENTS.SINGLE_SAVING_USER />,
		exact: true,
	},
	/**
	 * Administration
	 */
	{
		path: management.roles.path,
		element: <ADMINISTRATIONS.ROLES />,
		exact: true,
	},
	{
		path: management.administrators.path,
		element: <ADMINISTRATIONS.STAFF />,
		exact: true,
	},
	{
		path: `${management.roles.path}/edit/:id`,
		element: <ADMINISTRATIONS.EDIT_ROLE />,
		exact: true,
	},
	{
		path: `/profile`,
		element: <ADMINISTRATIONS.PROFILE />,
		exact: true,
	},
];
const contents = [...presentation];

export default contents;
