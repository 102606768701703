import React from 'react';
import { authMenu } from '../menu';
import DashboardHeader from '../pages/common/Headers/DashboardHeader';

const headers = [
	{ path: authMenu.login.path, element: null, exact: true },
	{ path: `${authMenu.joinTeam.path}/:slug`, element: null, exact: true },
	{ path: authMenu.forgetGroup.subMenu.forgetPassword.path, element: null, exact: true },
	{ path: authMenu.forgetGroup.subMenu.confirmation.path, element: null, exact: true },
	{ path: authMenu.forgetGroup.subMenu.resetPassword.path, element: null, exact: true },
	{
		path: `*`,
		element: <DashboardHeader />,
	},
];

export default headers;
