import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import Header, { HeaderLeft, HeaderRight } from '../../../layout/Header/Header';
import useDarkMode from '../../../hooks/useDarkMode';
import CommonHeaderRight from './CommonHeaderRight';
import Avatar from '../../../components/Avatar';
import Button from '../../../components/bootstrap/Button';
import UseToken from '../../../helpers/authService';
import Dropdown, {
	DropdownToggle,
	DropdownItem,
	DropdownMenu,
} from '../../../components/bootstrap/Dropdown';
import { profileMenu } from '../../../menu';
import { capitalizeFirstLetter } from '../../../helpers/number_utils';

const DashboardHeader = () => {
	const { darkModeStatus } = useDarkMode();
	const [userData, setUserData] = useState();
	const isUserLogged = UseToken().isUserLogged();
	const navigate = useNavigate();
	useEffect(() => {
		if (isUserLogged) {
			setUserData(JSON.parse(UseToken().getUserData()));
		}
	}, [isUserLogged]);
	return (
		<Header>
			<HeaderLeft>
				{userData ? (
					<div className='d-flex align-items-center'>
						<div className='row g-4'>
							<div className='col-md-auto'>
								<div
									className={classNames('fs-4', 'text-uppercase', {
										'text-dark': !darkModeStatus,
									})}>
									{capitalizeFirstLetter(userData.user.first_name)}{' '}
									{capitalizeFirstLetter(userData.user.last_name)}{' '}
									{capitalizeFirstLetter(userData.user.other_name)}
								</div>
								<div className='fs-6'>{userData.user.mobile.toUpperCase()}</div>
							</div>
						</div>
					</div>
				) : (
					<div />
				)}
			</HeaderLeft>
			<HeaderRight>
				<CommonHeaderRight
					afterChildren={
						<div className='col d-flex align-items-center'>
							{userData && (
								<Dropdown className='d-inline-block' setIsOpen={() => {}}>
									<DropdownToggle className='row cursor-pointer' hasIcon={false}>
										<div className='col d-flex align-items-center'>
											<div className='me-3'>
												<Avatar
													srcSet={userData.user.profile_photo_url}
													src={userData.user.profile_photo_url}
													size={48}
													color='primary'
												/>
											</div>
											<div>
												<div className='fw-bold fs-6 mb-0'>
													{userData.user.first_name.toUpperCase()}{' '}
													{userData.user.last_name.toUpperCase()}{' '}
													{userData.user.other_name &&
														userData.user.other_name.toUpperCase()}
												</div>
												<div className='text-muted'>
													<small>
														{userData.user.roles[0].name.toUpperCase()}
													</small>
												</div>
											</div>
										</div>
									</DropdownToggle>
									<DropdownMenu isAlignmentEnd>
										<DropdownItem>
											<Button
												icon={profileMenu.profile.icon}
												tag='a'
												onClick={() => navigate(profileMenu.profile.path)}>
												Profile
											</Button>
										</DropdownItem>
										<DropdownItem>
											<Button
												icon='PowerSettingsNew'
												tag='a'
												onClick={() => UseToken().logoutUser()}>
												Logout
											</Button>
										</DropdownItem>
									</DropdownMenu>
								</Dropdown>
							)}
						</div>
					}
				/>
			</HeaderRight>
		</Header>
	);
};

export default DashboardHeader;
